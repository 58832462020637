/* eslint-disable jsx-a11y/tabindex-no-positive */
import React from 'react';
import PropTypes from 'prop-types';

import Input from './Input';

import { AGE_GATE_DAY as AGE_GATE_DAY_QA } from '../../../constants/dataAutomationTagsQA';
import { AGE_GATE_DAY as AGE_GATE_DAY_ANALYTICS } from '../../../constants/dataAutomationTagsAnalytics';

const DayInput = ({ onChange, max, placeholder, tabIndex, ...rest }) => (
  <Input
    tabIndex={tabIndex}
    type="number"
    min="1"
    max={max}
    placeholder={placeholder}
    required
    onChange={onChange}
    minWidth={[null, null, 180]}
    data-automation={AGE_GATE_DAY_QA}
    data-analytics-id={AGE_GATE_DAY_ANALYTICS}
    {...rest}
  />
);

DayInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  max: PropTypes.number.isRequired,
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  tabIndex: PropTypes.number
};

DayInput.defaultProps = {
  placeholder: 'DD',
  tabIndex: null
};

export default DayInput;
