import React, { useContext, useReducer, createRef } from 'react';
import PropTypes from 'prop-types';
import { Flex, Text } from '@square-enix-private/vaquita-ui';
import { useTranslation } from 'react-i18next';

import styled from '@emotion/styled';
import { getDateAsString, padStart } from '../utils';
import { yearRange } from '../index';
import DayInput from './DayInput';
import MonthInput from './MonthInput';
import YearInput from './YearInput';
import InputWrapper from './InputWrapper';
import { LocaleContext } from '../../../i18n/utils';
import reducer, {
  initialState,
  ACTION_CHANGE_DAY,
  ACTION_CHANGE_MONTH,
  ACTION_CHANGE_YEAR
} from '../reducer';

import { AGE_GATE_SUBMIT } from '../../../constants/dataAutomationTagsQA';

import { Button } from '~/components/Buttons';

const FocusButton = styled(Button)`
  &:focus {
    border: solid;
    outline: -webkit-focus-ring-color auto 5px;
  }
`;

const formRef = createRef();
const focusToNextElem = (currentIndex) => {
  const element = formRef.current.querySelector(
    `[tabindex="${currentIndex + 1}"]`
  );

  if (element) {
    element.focus();
  }
};

const CustomForm = ({ submitDate }) => {
  const { t } = useTranslation('ageGate');
  const { locale } = useContext(LocaleContext);

  const [{ day, maxDay, month, year }, dispatch] = useReducer(
    reducer,
    initialState
  );
  const fieldsetOrder = locale === 'en-us' ? [2, 1, 3, 4] : [1, 2, 3, 4];

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        submitDate(
          getDateAsString(
            new Date(`${year}-${padStart(month)}-${padStart(day)}`)
          )
        );
      }}
      ref={formRef}
    >
      <Flex my={['medium', null, '1vw']}>
        <InputWrapper
          order={fieldsetOrder[0]}
          px={['medium', 'medium', 'medium']}
        >
          <DayInput
            max={maxDay}
            tabIndex={fieldsetOrder[0]}
            onChange={(e) => {
              if (e.target.value.length >= 2) {
                focusToNextElem(fieldsetOrder[0]);
              }

              return dispatch({
                type: ACTION_CHANGE_DAY,
                payload: parseInt(e.target.value, 10)
              });
            }}
          />
          <Text
            textAlign="center"
            color="text.primary"
            mt={['small', null, '0.3vw']}
          >
            {t('day')}
          </Text>
        </InputWrapper>
        <InputWrapper
          order={fieldsetOrder[1]}
          px={['medium', 'medium', 'medium']}
        >
          <MonthInput
            tabIndex={fieldsetOrder[1]}
            onChange={(e) => {
              if (e.target.value.length >= 2) {
                focusToNextElem(fieldsetOrder[1]);
              }

              return dispatch({
                type: ACTION_CHANGE_MONTH,
                payload: parseInt(e.target.value, 10)
              });
            }}
          />
          <Text
            textAlign="center"
            color="text.primary"
            mt={['small', null, '0.3vw']}
          >
            {t('month')}
          </Text>
        </InputWrapper>
        <InputWrapper
          order={fieldsetOrder[2]}
          px={['medium', 'medium', 'medium']}
        >
          <YearInput
            tabIndex={fieldsetOrder[2]}
            yearRange={yearRange}
            onChange={(e) => {
              if (e.target.value.length >= 4) {
                focusToNextElem(fieldsetOrder[2]);
              }

              return dispatch({
                type: ACTION_CHANGE_YEAR,
                payload: parseInt(e.target.value, 10)
              });
            }}
          />
          <Text
            textAlign="center"
            color="text.primary"
            mt={['small', null, '0.3vw']}
          >
            {t('year')}
          </Text>
        </InputWrapper>
      </Flex>
      <Flex justifyContent="center" mt={['small', 'xlarge']}>
        <FocusButton
          tabIndex={fieldsetOrder[3]}
          type="submit"
          data-automation={AGE_GATE_SUBMIT}
        >
          {t('submit')}
        </FocusButton>
      </Flex>
    </form>
  );
};

CustomForm.propTypes = {
  submitDate: PropTypes.func.isRequired
};

export default CustomForm;
