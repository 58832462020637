import { useTranslation } from 'react-i18next';

export const useFooterData = () => {
  const { t, i18n } = useTranslation('footer');
  const ratings = i18n.exists('footer:ratings')
    ? t('ratings', { returnObjects: true })
    : [];
  const studios = i18n.exists('footer:studioLogos')
    ? t('studioLogos', { returnObjects: true })
    : [];
  const platforms = i18n.exists('footer:platformLogos')
    ? t('platformLogos', { returnObjects: true })
    : [];
  const legal = i18n.exists('footer:legal')
    ? t('legal', { returnObjects: true })
    : undefined;

  const address = i18n.exists('footer:uskLegal') ? t('uskLegal') : undefined;
  const uskLink = i18n.exists('footer:uskLink') ? t('uskLink') : undefined;
  const uskLogo = i18n.exists('footer:uskLogo')
    ? t('uskLogo', { returnObjects: true })
    : undefined;
  const uskLogoImage = i18n.exists('footer:uskLogo.image')
    ? t('uskLogo.image', { returnObjects: true })
    : undefined;

  const links = i18n.exists('footer:externalLinks')
    ? t('externalLinks', { returnObjects: true })
    : [];

  const uskLegal = address
    ? {
      address,
      alt: '',
      link: uskLink,
      displayWidth: uskLogo ? uskLogo.displayWidth : undefined,
      displayHeight: uskLogo ? uskLogo.displayHeight : undefined,
      src: uskLogoImage ? uskLogoImage.src : undefined
    }
    : undefined;

  const internalLinks = i18n.exists('footer:internalLinks')
    ? t('internalLinks', { returnObjects: true })
    : [];

  const backgroundImage = i18n.exists('footer:backgroundImage')
    ? t('backgroundImage.image', { returnObjects: true })
    : [];

  const socialLinks = i18n.exists('footer:socialLinks')
    ? t('socialLinks', { returnObjects: true })
    : [];

  const heading = i18n.exists('footer:heading')
    ? t('heading', { returnObjects: true })
    : '';

  return {
    ratings,
    studios,
    platforms,
    legal,
    uskLegal,
    links,
    internalLinks,
    backgroundImage,
    socialLinks,
    heading
  };
};

export default useFooterData;
