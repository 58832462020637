import React from 'react';
import PropTypes from 'prop-types';

import Footer from './index';
import { useFooterData } from './FooterData';

const FooterWithData = ({ location, locale }) => {
  const {
    ratings,
    studios,
    platforms,
    legal,
    uskLegal,
    links,
    internalLinks,
    socialLinks,
    heading
  } = useFooterData();

  return (
    <Footer
      ratings={ratings}
      studios={studios}
      platforms={platforms}
      legal={legal}
      uskLegal={uskLegal}
      links={links}
      internalLinks={internalLinks}
      socialLinks={socialLinks}
      location={location}
      locale={locale}
      heading={heading}
    />
  );
};

FooterWithData.propTypes = {
  location: PropTypes.string,
  locale: PropTypes.node.isRequired
};

FooterWithData.defaultProps = {
  location: ''
};

export default FooterWithData;
