import styled from '@emotion/styled';
import { order } from 'styled-system';
import { Flex } from '@square-enix-private/vaquita-ui';

const InputWrapper = styled(Flex)`
  flex-direction: column-reverse;
  border: none;
  ${order};
`;

export default InputWrapper;
