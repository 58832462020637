import React from 'react';
import PropTypes from 'prop-types';
import { LogoSVG } from './SVGImage';
import { StyledLi, StyledUL } from './List';

import { FOOTER_LOGO as FOOTER_LOGO_QA } from '../../../constants/dataAutomationTagsQA';
import { FOOTER_LOGO as FOOTER_LOGO_ANALYTICS } from '../../../constants/dataAutomationTagsAnalytics';

export const Studios = ({ data }) => (
  <StyledUL data-type="logos" m="medium">
    {data.map(
      ({
        image,
        alt,
        id,
        link,
        fill,
        orientation,
        displayWidth,
        displayHeight
      }) => (
        <StyledLi key={id} m="medium">
          <a
            href={link}
            target="_blank"
            rel="noopener noreferrer"
            data-automation={FOOTER_LOGO_QA}
            data-analytics-id={FOOTER_LOGO_ANALYTICS}
          >
            <LogoSVG
              src={image.src}
              alt={alt}
              fill={fill}
              orientation={orientation}
              displayWidth={displayWidth}
              displayHeight={displayHeight}
            />
          </a>
        </StyledLi>
      )
    )}
  </StyledUL>
);

Studios.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      src: PropTypes.string,
      link: PropTypes.string,
      alt: PropTypes.string,
      fill: PropTypes.string,
      orientation: PropTypes.string,
      displayWidth: PropTypes.array,
      displayHeight: PropTypes.array,
      id: PropTypes.string.isRequired
    })
  )
};

Studios.defaultProps = {
  data: []
};

export default Studios;
