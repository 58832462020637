import { css } from '@emotion/core';

import iconBkgInverted from '~/assets/Buttons/button-background-white-grunge.png';
import styled from '@emotion/styled';

import outline from '~/assets/Buttons/outline.png';
import blueBkg from '~/assets/Buttons/button-background-blue-grunge.png';
import iconBkg from '../SocialLinks/icon-background.png';

export const InnerWrapper = styled.span`
  pointer-events: none;
  position: relative;
  z-index: 1;
`;

export const baseStyleSystem = {
  fontFamily: 'korolev-condensed, sans-serif',
  fontSize: 'large',
  color: 'light.primary',
  mx: 'medium',
  px: 'small'
};

export const baseCSS = ({ transition, hoverFill }) => css`
  cursor: pointer;
  position: relative;
  background-size: auto;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  text-transform: uppercase;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 100%;
  padding: 0;
  text-align: center;
  text-decoration: none;

  ::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: 0;
    z-index: 1;
  }

  :hover {
    transition: transform 0.5s;
    transition-timing-function: ease-in-out;
    transform: scale(1.1);
    & div svg {
      fill: ${hoverFill};
    }

    ::after {
      @supports (clip-path: circle(100% at 50% 50%)) {
        clip-path: circle(100% at 50% 50%);
      }
    }
  }

  :disabled:hover {
    filter: unset;
  }

  @media only screen and (min-width: 920px) {
    transition: ${transition || 'color 300ms, background 300ms'};
  }

  ::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    @supports (clip-path: circle(0% at 50% 50%)) {
      background: #fff;
    }
    background-size: auto;
    background-repeat: no-repeat;
    background-position: center;
    @supports (clip-path: circle(0% at 50% 50%)) {
      clip-path: circle(0% at 50% 50%);
    }
    transition: 0.5s;
    transition-timing-function: ease-in-out;
  }

  :disabled::after {
    transition: none;
  }

  &.active {
    background-image: url(${iconBkgInverted});
    color: rgb(0, 0, 0);
  }
`;

export const enhancedBaseCSS = ({ bgImg, bgColor }) => {
  if (!bgImg && !bgColor) {
    return null;
  }

  if (bgColor) {
    return css`
      background-color: ${bgColor};
    `;
  }

  return css`
    background-image: url(${bgImg});
  `;
};

const secondary = {
  minHeight: '60px',
  '&::after': {
    background: '#fff'
  },
  '& span': {
    width: '100%'
  },
  '&::before': {
    backgroundImage: `url(${blueBkg})`
  }
};

const demoPlatforms = {
  backgroundSize: 'cover',
  ':hover': {
    '&::after': {
      backgroundColor: '#fff'
    }
  },
  '&::before': {
    backgroundImage: `url(${outline})`
  }
};

export const variants = {
  primary: {
    ':hover': {
      color: '#000'
    },
    '&::after': {
      background: '#fff'
    }
  },
  secondary,
  smallIcons: {
    backgroundImage: `url('${iconBkg}')`,
    height: '40px'
  },
  demoPlatforms
};
