/* eslint-disable jsx-a11y/tabindex-no-positive */
import React from 'react';
import PropTypes from 'prop-types';

import Input from './Input';

import { AGE_GATE_MONTH as AGE_GATE_MONTH_QA } from '../../../constants/dataAutomationTagsQA';
import { AGE_GATE_MONTH as AGE_GATE_MONTH_ANALYTICS } from '../../../constants/dataAutomationTagsAnalytics';

const MonthInput = ({ onChange, placeholder, tabIndex, ...rest }) => (
  <Input
    tabIndex={tabIndex}
    type="number"
    min="1"
    max="12"
    placeholder={placeholder}
    required
    onChange={onChange}
    minWidth={[null, null, 180]}
    data-automation={AGE_GATE_MONTH_QA}
    data-analytics-id={AGE_GATE_MONTH_ANALYTICS}
    {...rest}
  />
);

MonthInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  tabIndex: PropTypes.number
};

MonthInput.defaultProps = {
  placeholder: 'MM',
  tabIndex: null
};

export default MonthInput;
