import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Flex, Box } from '@square-enix-private/vaquita-ui';
import styled from '@emotion/styled';

import Footer from '~/components/Footer/withData';

import StickyHeader from './StickyHeader';
import { SEHeaderHeight } from '~/constants/site';

import BgTexture from '~/assets/pages-background.jpg';

const ContentWrapper = styled(Flex)`
  position: relative;
  flex-direction: column;
  margin-top: ${SEHeaderHeight}px;
`;

const Background = styled(Box)`
  flex-direction: column;
  position: fixed;
  background-image: ${({ homepageRoute }) => homepageRoute && `url(${BgTexture})`};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
  z-index: -1;
  @media screen and (max-width: 768px) {
    background-size: cover;
  }
`;

const Layout = ({ children, locale, location }) => {
  const homepageRoute = Boolean(location === `/${locale}/`);

  return (
    <Fragment>
      <Background homepageRoute={homepageRoute} />
      <ContentWrapper>
        <StickyHeader locale={locale} location={location} />
        {children}
      </ContentWrapper>
      <Footer locale={locale} location={location} />
    </Fragment>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  locale: PropTypes.node.isRequired,
  location: PropTypes.string.isRequired
};

Layout.defaultProps = {};

export default Layout;
