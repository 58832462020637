import styled from '@emotion/styled';
import { space } from 'styled-system';
import { Box } from '@square-enix-private/vaquita-ui';

export { default as Platforms } from './platforms';
export { default as Studios } from './studios';
export { default as LegalContent } from './legalContent';
export { default as Links } from './links';
export { default as SocialLinks } from './socialLinks';
export { default as USK } from './usk';
export { default as Ratings } from './ratings';
export { default as LocaleSwitcher } from './localeSwitcher';

export const FooterContainer = styled.footer`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  text-align: center;
  align-items: center;
  align-content: center;
  color: ${({ theme }) => theme.colors.text.primary};
  background-color: #000;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: center;
  ${space};
`;

export const Divider = styled(Box)`
  width: 100%;
  height: 1px;
  margin: 15px 0px;
  background-color: ${({ theme }) => theme.colors.text.primary};
`;
