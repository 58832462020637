import {
  order,
  borderColor,
  space,
  fontSize,
  minWidth,
  textAlign
} from 'styled-system';
import styled from '@emotion/styled';

const Input = styled.input`
  background: none;
  min-height: 40px;
  outline: none;
  text-transform: uppercase;
  transition: border-color 300ms;
  ${({ theme }) => `
  border: 2px solid #696969;
  color: ${theme.colors.text.primary};
  &:hover,:focus {
    border-color: ${theme.colors.text.primary};
  }
  `}
  ${({ cursor }) => cursor && `cursor: ${cursor}`};
  ${order};
  ${borderColor};
  ${space};
  ${fontSize};
  ${minWidth};
  ${textAlign};

  &[type='number']::-webkit-inner-spin-button,
  &[type='number']::-webkit-outer-spin-button {
    opacity: 0;
  }

  &[type='number']::-webkit-textfield-decoration-container {
    padding-left: 13px;
  }

  /* date mobile */
  &[type='date']::-webkit-datetime-edit {
    padding-left: 4px;
    display: flex;
    justify-content: flex-end;
  }

  &[type='date']::-webkit-date-and-time-value {
    margin-left: 18px;
  }

  &[type='number'] {
    -moz-appearance: textfield;
    appearance: textfield;
  }

  .datetime-input-edit-wrapper {
    outline: 1px solid red;
  }
`;

export default Input;
