const onClientEntry = () => {
  window.newsletterSubscriptionConfig = {
    trigger: '50%',
    locale: 'en_GB',
    tag: 'OUTRIDERS_NL',
    gameName: 'OUTRIDERS',
    logo: '/logo.png',
    theme: {
      backgroundColor: '#000',
      backgroundImage: '/modal-back.jpg',
      borderColor: '#7802E5',
      textColor: '#ffffff',
      inputBackgroundColor: '#1A1A1A',
      inputBorderColor: 'transparent',
      inputTextColor: '#ffffff',
      linkColor: '#fff',
      thankyouTextColor: '#ffffff',
      buttonTextColor: '#ffffff',
      buttonBackgroundColor: '#ffffff',
      buttonBackgroundImage: '',
      closeIconColor: '#7802E5',
      hideButtonColor: '#5c5c5c',
      dobBackgroundColor: '#000',
      titleColor: '#fff'
    }
  };
};

export default onClientEntry;
