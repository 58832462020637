/* eslint-disable react/prop-types */
import React from 'react';
import { Global, css } from '@emotion/core';
import { withTheme } from 'emotion-theming';
import emotionNormalize from 'emotion-normalize';
import FontsFace from './fonts';

const GlobalStyles = ({ theme: { fonts } }) => (
  <Global
    styles={css`
      ${emotionNormalize}
      body {
        ${FontsFace};
        font-family: ${fonts.OpenSans.normal};
        color: #fff;
        background-color: #000;

        &.se-no-scroll {
          overflow-y: hidden;
        }
      }

      button {
        background-color: unset;
        border: none;
        text-decoration: none;
        text-align: center;
      }

      #logo .black {
        fill: #fff;
      }
    `}
  />
);

export default withTheme(GlobalStyles);
