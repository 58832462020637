import React from 'react';
import PropTypes from 'prop-types';
import { Text } from '@square-enix-private/vaquita-ui';
import { useTranslation } from 'react-i18next';

import Wrapper from './Wrapper';
import NativeForm from './NativeForm';
import CustomForm from './CustomForm';
import { MobileOnly, DesktopOnly } from '../../Buckets';

const Form = ({ submitDate }) => {
  const { t } = useTranslation('ageGate');

  return (
    <Wrapper>
      <Text textAlign="center" color="text.primary" as="p">
        {t('title')}
      </Text>
      <MobileOnly>
        <NativeForm submitDate={submitDate} />
      </MobileOnly>
      <DesktopOnly>
        <CustomForm submitDate={submitDate} />
      </DesktopOnly>
    </Wrapper>
  );
};

Form.propTypes = {
  submitDate: PropTypes.func.isRequired
};

export default Form;
