import { connect } from 'react-redux';

import { valid } from '../../../actions/ageGate';

const Unlocked = ({ children, valid }) => {
  valid();

  return children;
};

const mapDispatchToProps = { valid };

export default connect(
  null,
  mapDispatchToProps
)(Unlocked);
