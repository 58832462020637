import React from 'react';
import PropTypes from 'prop-types';
import { createAgeGate } from '@square-enix-private/age-gate';
import { useTranslation } from 'react-i18next';

import Form from './components/Form';
import Locked from './components/Locked';
import Unlocked from './components/Unlocked';
import { getInitialState } from './utils';

export const yearRange = 100;

const AgeGate = ({ children, minAge }) => {
  const { t } = useTranslation('ageGate');
  const Gate = createAgeGate({
    Form,
    Locked,
    initialState: getInitialState(),
    minAge: minAge || t('minAge')
  });

  return (
    <Gate>
      <Unlocked>{children}</Unlocked>
    </Gate>
  );
};

AgeGate.propTypes = {
  children: PropTypes.any.isRequired,
  minAge: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

AgeGate.defaultProps = {
  minAge: null
};

export default AgeGate;
