// AGE GATE
export const AGE_GATE_DAY = 'AGE_GATE_DAY';
export const AGE_GATE_MONTH = 'AGE_GATE_MONTH';
export const AGE_GATE_YEAR = 'AGE_GATE_YEAR';
export const AGE_GATE_SUBMIT = 'AGE_GATE_SUBMIT';
export const AGE_GATE_INPUT_MOBILE = 'AGE_GATE_INPUT_MOBILE';
export const AGE_GATE_SUBMIT_MOBILE = 'AGE_GATE_SUBMIT_MOBILE';

// COOKIE NOTICE
export const COOKIE_NOTICE = 'COOKIE_NOTICE';
export const COOKIE_NOTICE_CLOSE_BUTTON = 'COOKIE_NOTICE_CLOSE_BUTTON';

// FOOTER
export const FOOTER_RATING = 'FOOTER_RATING';
export const FOOTER_PLATFORM = 'FOOTER_PLATFORM';
export const FOOTER_LOGO = 'FOOTER_LOGO';
export const FOOTER_LEGAL_LINE = 'FOOTER_LEGAL_LINE';
export const FOOTER_LEGAL_USK = 'FOOTER_LEGAL_USK';
export const FOOTER_LEGAL_LINK = 'FOOTER_LEGAL_LINK';

// MEDIA
export const MEDIA_BUTTON_CLOSE = 'MEDIA_BUTTON_CLOSE';
export const TRAILER_WRAPPER = 'TRAILER_WRAPPER';
export const TRAILER_BUTTON_PLAY = 'TRAILER_BUTTON_PLAY';

// SOCIAL BUTTONS
export const SOCIAL_WRAPPER = 'SOCIAL_WRAPPER';
export const SOCIAL_BUTTON = 'SOCIAL_BUTTON';

// NEWSLETTER
export const NEWSLETTER_INPUT = 'NEWSLETTER_INPUT';
export const NEWSLETTER_BUTTON_SUBMIT = 'NEWSLETTER_BUTTON_SUBMIT';
export const NEWSLETTER_MESSAGE = 'NEWSLETTER_MESSAGE';

// LOCALES
export const ACTIVE_LOCALE = 'ACTIVE_LOCALE';
export const AVAILABLE_LOCALE = 'AVAILABLE_LOCALE';
