import styled from '@emotion/styled';
import { space } from 'styled-system';

export const StyledUL = styled.ul`
  list-style: none;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 0;
  ${space};
`;

export const StyledLi = styled.li`
  margin: unset;
  display: flex;
  justify-content: center;
  flex-direction: column;
  ${space};
`;

export const SocialLinksUL = styled.ul`
  margin: unset;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  ${space};
`;

export const ExternalLinksUL = styled.ul`
  list-style: none;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 0px;
`;

export default {
  StyledUL,
  StyledLi,
  SocialLinksUL
};
