import { getCookie } from '@square-enix-private/fe-cookie';

import { COOKIE_NAME, VALID, INVALID } from '~/constants/ageGate';

const value = typeof window !== 'undefined' && getCookie(COOKIE_NAME);

const initialState = { isValid: value || '' };

export default (state = initialState, action) => {
  switch (action.type) {
    case VALID:
      return {
        ...state,
        isValid: true
      };
    case INVALID:
      return {
        ...state,
        isValid: false
      };

    default:
      return state;
  }
};
