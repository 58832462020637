import { VISIBLE, HIDDEN } from '~/constants/videoPlayer';

const initialState = { isVisible: false };

export default (state = initialState, action) => {
  switch (action.type) {
    case VISIBLE:
      return {
        ...state,
        isVisible: true,
        videoID: action.payload
      };
    case HIDDEN:
      return {
        ...state,
        isVisible: false,
        videoID: null
      };

    default:
      return state;
  }
};
