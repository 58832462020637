import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Text } from '@square-enix-private/vaquita-ui';
import { StyledLi, ExternalLinksUL } from './List';

import { FOOTER_LEGAL_LINK as FOOTER_LEGAL_LINK_QA } from '../../../constants/dataAutomationTagsQA';
import { FOOTER_LEGAL_LINE as FOOTER_LEGAL_LINE_ANALYTICS } from '../../../constants/dataAutomationTagsAnalytics';

const Link = styled.a`
  text-decoration: none;
  text-transform: uppercase;
`;

export const Links = ({ data }) => (
  <ExternalLinksUL data-type="links" mb="medium">
    {data.map(({ title, link, id }) => (
      <StyledLi key={id} m={['small', 'medium']}>
        <Link
          href={link}
          target="_blank"
          rel="noopener noreferrer"
          data-automation={FOOTER_LEGAL_LINK_QA}
          data-analytics-id={FOOTER_LEGAL_LINE_ANALYTICS}
        >
          <Text color="text.primary" fontSize="16px">
            {title}
          </Text>
        </Link>
      </StyledLi>
    ))}
  </ExternalLinksUL>
);

Links.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      copy: PropTypes.string,
      link: PropTypes.string,
      id: PropTypes.string.isRequired
    })
  )
};

Links.defaultProps = {
  data: []
};

export default Links;
