import React from 'react';
import PropTypes from 'prop-types';
import ReactSVG from 'react-svg';
import { Flex } from '@square-enix-private/vaquita-ui';
import { StyledLi, SocialLinksUL } from './List';

export const SocialLinks = ({ data }) => (
  <Flex>
    <SocialLinksUL data-type="logos" m="medium">
      {data.map(({ image, fill, id, link, displayWidth, displayHeight }) => (
        <StyledLi key={id} m="medium">
          <a href={link} target="_blank" rel="noopener noreferrer">
            <ReactSVG
              width={displayWidth}
              height={displayHeight}
              src={image.src}
              fill={fill}
            />
          </a>
        </StyledLi>
      ))}
    </SocialLinksUL>
  </Flex>
);

SocialLinks.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      src: PropTypes.string,
      link: PropTypes.string,
      alt: PropTypes.string,
      fill: PropTypes.string,
      orientation: PropTypes.string,
      displayWidth: PropTypes.array,
      displayHeight: PropTypes.array,
      id: PropTypes.string.isRequired
    })
  )
};

SocialLinks.defaultProps = {
  data: []
};

export default SocialLinks;
