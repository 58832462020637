import React from 'react';
import PropTypes from 'prop-types';
import { Flex, Box } from '@square-enix-private/vaquita-ui';
import SEHeader from '@square-enix-private/fe-header';
import styled from '@emotion/styled';
import SiteMenu from '../siteMenu';

const StickyHeaderContainer = styled(Flex)`
  position: fixed;
  top: 0;
  width: 100%;
  justify-content: flex-end;
  flex-direction: column;
  z-index: 10;
`;

const StickyHeader = ({ locale, location }) => (
  <StickyHeaderContainer>
    <Box>
      <SEHeader
        locale={locale}
        siteIndex={process.env.DP_SITE_INDEX}
        siteTag={process.env.SITE_TAG}
        headerBarSrc={process.env.GAME_HUB_SRC}
        membershipUrl={process.env.MEMBERSHIP_ROOT}
        moreGames
      />
    </Box>
    <SiteMenu locale={locale} location={location} />
  </StickyHeaderContainer>
);

StickyHeader.propTypes = {
  locale: PropTypes.node.isRequired,
  location: PropTypes.string.isRequired
};

export default StickyHeader;
