import React from 'react';
import PropTypes from 'prop-types';
import GatsbyLink from 'gatsby-link';
import styled from '@emotion/styled';
import {
  color,
  fontSize,
  fontWeight,
  style,
  space,
  textAlign
} from 'styled-system';

export const activeColor = style({
  prop: 'activeColor',
  cssProperty: 'color',
  key: 'colors'
});

export const activeFont = style({
  prop: 'activeFont',
  cssProperty: 'font-family',
  key: 'fonts'
});

export const hoverColor = style({
  prop: 'hoverColor',
  cssProperty: 'color',
  key: 'colors'
});

const props = [
  'hoverColor',
  'activeColor',
  'activeFont',
  'textAlign',
  'letterSpacing'
];

export const StyledLink = styled(GatsbyLink, {
  shouldForwardProp: (prop) => !props.includes(prop)
})`
  text-decoration: none;
  text-transform: uppercase;
  font-family: korolev-condensed, sans-serif;
  ${fontSize};
  ${fontWeight};
  ${color};
  ${space};
  ${textAlign};
  transition: 0.3s;

  &:hover {
    ${hoverColor};
    transition: 0.3s;
  }

  &.active {
    ${activeColor};
    ${activeFont};
  }
`;

const Link = ({ children, ...props }) => (
  <StyledLink
    activeColor="text.tertiary"
    color="text.primary"
    hoverColor="text.tertiary"
    fontSize="large"
    m={['small', null, null, 'medium']}
    textAlign="center"
    {...props}
  >
    {children}
  </StyledLink>
);

Link.propTypes = {
  children: PropTypes.any
};

Link.defaultProps = {
  children: null
};

export default Link;
