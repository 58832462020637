import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Flex } from '@square-enix-private/vaquita-ui';
import { useTranslation } from 'react-i18next';

import { getDateAsString } from '../utils';
import { yearRange } from '../index';
import Input from './Input';
import { Button } from '~/components/Buttons';

import {
  AGE_GATE_INPUT_MOBILE as AGE_GATE_INPUT_MOBILE_QA,
  AGE_GATE_SUBMIT_MOBILE
} from '../../../constants/dataAutomationTagsQA';
import { AGE_GATE_INPUT_MOBILE as AGE_GATE_INPUT_MOBILE_ANALYTICS } from '../../../constants/dataAutomationTagsAnalytics';

const NativeForm = ({ submitDate }) => {
  const { t } = useTranslation('ageGate');
  const [date, setDate] = useState(new Date());

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        submitDate(getDateAsString(date));
      }}
    >
      <Flex flexDirection="column" alignItems="center" minWidth={320}>
        <Input
          minWidth={180}
          textAlign="center"
          fontSize="medium"
          type="date"
          min={`${new Date().getFullYear() - yearRange}-01-01`}
          mb="medium"
          onChange={({ currentTarget: { value } }) => {
            if (value.length > 0) {
              setDate(new Date(value));
            } else {
              setDate(new Date());
            }
          }}
          data-automation={AGE_GATE_INPUT_MOBILE_QA}
          data-analytics-id={AGE_GATE_INPUT_MOBILE_ANALYTICS}
          required
        />
        <Button type="submit" data-automation={AGE_GATE_SUBMIT_MOBILE}>
          {t('submit')}
        </Button>
      </Flex>
    </form>
  );
};

NativeForm.propTypes = {
  submitDate: PropTypes.func.isRequired
};

export default NativeForm;
