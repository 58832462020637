import { setCookie } from '@square-enix-private/fe-cookie';

import {
  VALID,
  INVALID,
  COOKIE_NAME,
  VALID_COOKIE,
  INVALID_COOKIE,
  cookieDuration
} from '../constants/ageGate';

export const valid = () => {
  setCookie(COOKIE_NAME, VALID_COOKIE, cookieDuration);

  return { type: VALID };
};

export const invalid = () => {
  setCookie(COOKIE_NAME, INVALID_COOKIE, cookieDuration);

  return { type: INVALID };
};

export default {
  valid,
  invalid
};
