/* eslint-disable react/prop-types */
import React, { useContext, Fragment } from 'react';
import { GTMProvider } from '@square-enix-private/fe-gtm/dist/GTMProvider';
import { setCookie } from '@square-enix-private/fe-cookie';
import Helmet from 'react-helmet';
import { addResources, useChangeLanguage, LocaleContext } from './i18n/utils';
import Layout from './components/Layout';
import SEO from './components/SEO/withData';
import Newsletter from './components/Newsletter';

const DataToi18n = (data) => {
  if (!data) {
    return [];
  }

  const keys = Object.keys(data);

  return keys.map((key) => ({
    ns: key,
    content: Array.isArray(data[key]) ? { content: data[key] } : data[key]
  }));
};

export const App = ({
  pageContext = {
    data: [],
    locale: 'en-gb',
    gtmId: '',
    environment: '',
    availableLocales: []
  },
  location,
  children
}) => {
  const {
    locale = 'en-gb',
    gtmId,
    environment,
    availableLocales
  } = pageContext;
  const {
    props: { data }
  } = children;
  const { setLocale } = useContext(LocaleContext);

  useChangeLanguage(locale);
  addResources(DataToi18n(data), locale);
  setLocale(locale);

  setCookie('locale', locale);

  const currentlocal = location.pathname ? location.pathname : '';

  return (
    <Fragment>
      <Helmet>
        <script src={process.env.NEWSLETTER_SRC} type="text/javascript" async />
      </Helmet>
      <GTMProvider
        context={{
          language: locale,
          country: locale,
          site: 'outriders',
          siteType: 'gameSite',
          environment,
          gtmId
        }}
      >
        <SEO />
        <Newsletter />
        <Layout
          locale={locale}
          availableLocales={availableLocales}
          location={currentlocal}
        >
          {children}
        </Layout>
      </GTMProvider>
      <noscript>
        <iframe
          title="gtm-no-script"
          src={`https://www.googletagmanager.com/ns.html?id=${gtmId}`}
          height="0"
          width="0"
          style={{ display: 'none', visibility: 'hidden' }}
        />
      </noscript>
    </Fragment>
  );
};

export default App;
