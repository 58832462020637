import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Box } from '@square-enix-private/vaquita-ui';

const Hamburger = styled(Box)`
  cursor: pointer;
  position: relative;
  transition: 0.3s ease-in-out;
  margin: 6px;
  font-family: korolev-condensed, sans-serif;
  font-size: 22px;
`;

const HamburgerButton = ({ onClick, isOpen, ...rest }) => (
  <Hamburger onClick={onClick} isOpen={isOpen} {...rest}>
    MENU
  </Hamburger>
);

HamburgerButton.propTypes = {
  onClick: PropTypes.func,
  isOpen: PropTypes.bool
};

HamburgerButton.defaultProps = {
  isOpen: false,
  onClick: (x) => x
};

export default HamburgerButton;
