export const ACTION_CHANGE_DAY = 'DOB_FORM::CHANGE_DAY';
export const ACTION_CHANGE_MONTH = 'DOB_FORM::CHANGE_MONTH';
export const ACTION_CHANGE_YEAR = 'DOB_FORM::CHANGE_YEAR';
export const initialState = {
  day: 1,
  maxDay: 31,
  month: 1,
  year: 1900
};

const getMaxDay = ({ month, year }) => new Date(year, month, 0).getDate();

const reducer = (state, action) => {
  switch (action.type) {
    case ACTION_CHANGE_DAY:
      return {
        ...state,
        day: action.payload
      };
    case ACTION_CHANGE_MONTH:
      return {
        ...state,
        month: action.payload,
        maxDay: getMaxDay({ month: action.payload, year: state.year })
      };
    case ACTION_CHANGE_YEAR:
      return {
        ...state,
        year: action.payload,
        maxDay: getMaxDay({ month: state.month, year: action.payload })
      };
    default:
      return state;
  }
};

export default reducer;
