import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Flex } from '@square-enix-private/vaquita-ui';
import { useTranslation } from 'react-i18next';
import { DesktopOnly, MobileOnly } from '~/components/Buckets';
import HamburgerMenu from './HamburgerButton';
import Link from './link';
import {
  StickyMobileContainer,
  MenuContainer,
  FlexWrapper,
  Head,
  Icon,
  ToogleNewsletter
} from './elements';

const MobileSiteMenu = ({ locale, location }) => {
  const { t } = useTranslation('menu');
  const [isOpen, setOpen] = useState(false);
  const homepageImg = t('homepageIcon.image.src');
  const homepageImgHover = t('homepageIconActive.image.src');
  const homepageImgActive = t('homepageIconActive.image.src');
  const bgImg = t('backgroundImg.image.src');

  const newsletterButton = useTranslation('generics').t('newsletterButton');

  return (
    <>
      <DesktopOnly>
        <Flex justifyContent="center" width={1}>
          <Head
            justifyContent="space-between"
            flexDirection="row"
            px="xsmall"
            m="auto"
            maxWidth="100%"
            bgImg={bgImg}
          >
            <Flex
              height="100%"
              flex="1 0 auto"
              justifyContent="left"
              alignItems="center"
            >
              <Link to={`/${locale}/`}>
                <Icon
                  width="50px"
                  className={location === `/${locale}/` ? 'active' : 'hover'}
                  homepageImg={homepageImg}
                  homepageImgHover={homepageImgHover}
                  homepageImgActive={homepageImgActive}
                />
              </Link>
              <Link
                to={`/${locale}/buy`}
                className={location === `/${locale}/buy` ? 'active' : ''}
              >
                {t('buy')}
              </Link>
              <Link
                to={`/${locale}/broadcast`}
                className={location === `/${locale}/broadcast` ? 'active' : ''}
              >
                {t('outridersBroadcast')}
              </Link>
              <Link
                to={`/${locale}/demo`}
                className={location === `/${locale}/demo` ? 'active' : ''}
              >
                {t('demo')}
              </Link>
              <Link
                to={`/${locale}/faq`}
                className={location === `/${locale}/faq` ? 'active' : ''}
              >
                {t('faq')}
              </Link>
            </Flex>
            <FlexWrapper
              my={['xsmall', 'small']}
              alignItems="center"
              data-automation="newsletters-button"
              onClick={() => {
                const { newsletterSubscriptionConfig } = window;

                if (
                  newsletterSubscriptionConfig
                  && newsletterSubscriptionConfig.show
                ) {
                  newsletterSubscriptionConfig.show();
                }
              }}
            >
              <ToogleNewsletter mx="medium">
                {newsletterButton}
              </ToogleNewsletter>
            </FlexWrapper>
          </Head>
        </Flex>
      </DesktopOnly>
      <MobileOnly>
        {isOpen && (
          <MenuContainer width={1} pt="xlarge" px="large" margin="auto">
            <Flex
              width={1}
              height="100%"
              mt="large"
              flexDirection="column"
              justifyContent="flex-start"
              alignItems="flex-start"
              fontSize={['large', 'xlarge']}
            >
              <Link
                to={`/${locale}/#home`}
                onClick={() => setOpen(false)}
                className={location === `/${locale}/` ? 'active' : ''}
              >
                {t('home')}
              </Link>
              <Link
                to={`/${locale}/buy`}
                onClick={() => setOpen(false)}
                className={location === `/${locale}/buy` ? 'active' : ''}
              >
                {t('buy')}
              </Link>
              <Link
                to={`/${locale}/broadcast`}
                onClick={() => setOpen(false)}
                className={location === `/${locale}/broadcast` ? 'active' : ''}
              >
                {t('outridersBroadcast')}
              </Link>
              <Link
                to={`/${locale}/demo`}
                onClick={() => setOpen(false)}
                className={location === `/${locale}/demo` ? 'active' : ''}
              >
                {t('demo')}
              </Link>
              <Link
                to={`/${locale}/faq`}
                onClick={() => setOpen(false)}
                className={location === `/${locale}/faq` ? 'active' : ''}
              >
                {t('faq')}
              </Link>
            </Flex>
          </MenuContainer>
        )}
        <StickyMobileContainer width={1}>
          <FlexWrapper
            bg="#000"
            width={1}
            height="100%"
            alignItems="center"
            justifyContent="space-between"
            px="medium"
          >
            <HamburgerMenu
              my="small"
              onClick={() => setOpen(!isOpen)}
              isOpen={isOpen}
              data-ab="MOBILE_MENU_BUTTON"
            />
            <ToogleNewsletter
              type="submit"
              data-automation="newsletters-button"
              onClick={() => {
                const { newsletterSubscriptionConfig } = window;

                if (
                  newsletterSubscriptionConfig
                  && newsletterSubscriptionConfig.show
                ) {
                  newsletterSubscriptionConfig.show();
                }
              }}
            >
              {newsletterButton}
            </ToogleNewsletter>
          </FlexWrapper>
        </StickyMobileContainer>
      </MobileOnly>
    </>
  );
};

MobileSiteMenu.propTypes = {
  locale: PropTypes.node.isRequired,
  location: PropTypes.string.isRequired
};

export default MobileSiteMenu;
