/* eslint-disable jsx-a11y/tabindex-no-positive */
import React from 'react';
import PropTypes from 'prop-types';

import Input from './Input';

import { AGE_GATE_YEAR as AGE_GATE_YEAR_QA } from '../../../constants/dataAutomationTagsQA';
import { AGE_GATE_YEAR as AGE_GATE_YEAR_ANALYTICS } from '../../../constants/dataAutomationTagsAnalytics';

const YearInput = ({ onChange, placeholder, yearRange, tabIndex, ...rest }) => (
  <Input
    tabIndex={tabIndex}
    type="number"
    min={new Date().getFullYear() - yearRange}
    max={new Date().getFullYear()}
    placeholder={placeholder}
    required
    onChange={onChange}
    minWidth={[null, null, 180]}
    data-automation={AGE_GATE_YEAR_QA}
    data-analytics-id={AGE_GATE_YEAR_ANALYTICS}
    {...rest}
  />
);

YearInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  yearRange: PropTypes.number,
  tabIndex: PropTypes.number
};

YearInput.defaultProps = {
  placeholder: 'YYYY',
  yearRange: 100,
  tabIndex: null
};

export default YearInput;
