import { Flex, Box, Button } from '@square-enix-private/vaquita-ui';
import styled from '@emotion/styled';
import { space, width, position } from 'styled-system';
import { SEHeaderHeight } from '~/constants/site';
import newsletterBkg from '~/assets/Buttons/newsletter-bg.png';
import { transform } from '~/custom/styled-system';
import createButtonFactory from '../Buttons/createButtonFactory';

export const StickyMobileContainer = styled(Flex)`
  position: fixed;
  top: ${SEHeaderHeight}px;
  height: 50px;
  align-items: center;
  justify-content: space-between;
  > * {
    pointer-events: auto;
  }
`;

export const MenuContainer = styled.nav`
  ${space};
  ${width};
  background-color: rgba(0, 0, 0, 0.88);
  height: calc(100vh - ${SEHeaderHeight}px);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: absolute;
  top: ${SEHeaderHeight}px;
  left: 0px;
  transition: all 0.3s ease-in-out 0s;
  overflow: hidden;
`;

export const Head = styled(Flex)`
  position: absolute;
  top: ${SEHeaderHeight}px;
  height: 60px;
  width: 100%;
  background-image: linear-gradient(rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.7));
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
  background-position: center;
  align-content: center;
`;

export const Icon = styled(Box)`
  min-height: 50px;
  background-image: url(${({ homepageImg }) => homepageImg});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  image-rendering: pixelated;
  transition: 0.3s;

  &.active {
    background-image: url(${({ homepageImgActive }) => homepageImgActive});
  }

  :hover {
    background-image: url(${({ homepageImgHover }) => homepageImgHover});
  }
`;

const HoverWrapper = createButtonFactory(Flex);

export const HoverBox = styled(HoverWrapper)`
  &:hover button::after {
    background: #000;
  }
`;

export const ToogleNewsletter = styled(Button)`
  font-family: korolev-condensed, sans-serif;
  font-size: 22px;
  ${space};
  border: none;
  background: none;
  color: inherit;
  position: relative;
  cursor: pointer;
  user-select: none;
  text-transform: uppercase;

  &:hover {
    background: #000;
    color: ${({ theme: { colors } }) => colors.text.tertiary};
  }
`;

export const NewsLetterContainer = styled(Flex)`
  position: absolute;
  top: 100%;
  background-image: url("${newsletterBkg}");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  ${transform};
  ${position};
  display: ${({ isOpen }) => (isOpen ? 'inherit' : 'none')};
  filter: drop-shadow(2px 4px 6px black);
`;

export const FlexWrapper = styled(Flex)`
  position: relative;
`;
