import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const updateConfig = ({ minAge, javaLocale }) => {
  let config = {};

  if (typeof window !== 'undefined') {
    config = window.newsletterSubscriptionConfig;
  }

  config.minimumAge = minAge ? parseFloat(minAge) : null;
  config.locale = javaLocale;

  if (config.changeLocale) {
    config.changeLocale(javaLocale);
  }

  if (config.updateConfig) {
    config.updateConfig({
      ...config
    });
  }
};

const Newsletter = () => {
  const minAge = useTranslation('ageGate').t('newsletterMinAge');
  const javaLocale = useTranslation('generics').t('javaLocaleCode');

  updateConfig({ minAge, javaLocale });

  useEffect(() => {
    updateConfig({ minAge, javaLocale });
  });

  return null;
};

export default Newsletter;
