/* eslint-disable react/default-props-match-prop-types */
import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { width, height } from 'styled-system';

import { Flex } from '@square-enix-private/vaquita-ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { MEDIA_BUTTON_CLOSE } from '../../constants/dataAutomationTagsQA';

import {
  Background,
  FlexRelative,
  CloseButton,
  LightBoxContainer,
  ModalOptionsWrapper,
  FlexContainer
} from './elements';

const enableScroll = () => {
  document.getElementsByTagName('body')[0].classList.remove('se-no-scroll');
};

const Lightbox = ({ children, onCloseModal }) => {
  useEffect(() => {
    document.getElementsByTagName('body')[0].classList.add('se-no-scroll');
  });

  const escKeyPress = useCallback((event) => {
    if (event.key === 'Escape') {
      onCloseModal();
    }
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', escKeyPress, false);

    return () => {
      document.removeEventListener('keydown', escKeyPress, false);
    };
  }, [escKeyPress]);

  return (
    <LightBoxContainer width={1} height="100vh">
      <FlexRelative width={1}>
        <Background
          onClick={() => {
            enableScroll();
            onCloseModal();
          }}
        />
        <ModalOptionsWrapper justifyContent="flex-end">
          <CloseButton
            variant="secondary"
            hoverTextColor="light.primary"
            m="medium"
            mx="large"
            width={40}
            height={40}
            data-automation={MEDIA_BUTTON_CLOSE}
            onClick={() => {
              enableScroll();
              onCloseModal();
            }}
          >
            <FontAwesomeIcon size="2x" icon={faTimes} />
          </CloseButton>
        </ModalOptionsWrapper>
        <Flex
          width={1}
          height={['100%']}
          flexDirection="column"
          justifyContent="center"
        >
          <FlexContainer width={1} height={['50vh', null, '100%']} p="large">
            {children}
          </FlexContainer>
        </Flex>
      </FlexRelative>
    </LightBoxContainer>
  );
};

Lightbox.propTypes = {
  children: PropTypes.any.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  ...width.propTypes,
  ...height.propTypes
};

Lightbox.defaultProps = {
  width: '100%',
  height: '100%'
};

export default Lightbox;
