module.exports = [{
      plugin: require('../plugins/gastsby-plugin-gtm-data-layer/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"OUTRIDERS","theme_color":"#000000","display":"minimal-ui","icon":"static/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"d5749a58b663570214f994d07ae20799"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"precachePages":["en-us/*"],"workboxConfig":{"importWorkboxFrom":"cdn","globPatterns":["**/*.{js,json,png,html,css}"],"runtimeCaching":[{"urlPattern":{},"handler":"NetworkFirst","options":{"cacheName":"jscss-cache","expiration":{"maxEntries":15,"maxAgeSeconds":3600},"cacheableResponse":{"statuses":[0,200]},"matchOptions":{"ignoreSearch":true}}}],"offlineGoogleAnalytics":true,"skipWaiting":true,"clientsClaim":true,"ignoreURLParametersMatching":[{}]}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
