import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import SEO from './index';

const SEOData = ({ translationID }) => {
  const { t, i18n } = useTranslation(translationID);

  const imagePath = t('image.image.src');
  const keywords = i18n.exists(`${translationID}:keywords`)
    ? t('keywords', { returnObjects: true })
    : undefined;

  return (
    <SEO
      siteName={t('siteName')}
      title={t('title')}
      description={t('description')}
      ogTitle={t('ogTitle')}
      ogType={t('ogType')}
      ogDescription={t('ogDescription')}
      ogImage={imagePath}
      twitterCard={t('twitterCard')}
      twitterDescription={t('twitterDescription')}
      twitterCreator={t('author')}
      twitterImage={imagePath}
      twitterImageAlt={t('imageAlt')}
      keywords={keywords}
      lang={i18n.language.toLocaleLowerCase()}
    />
  );
};

SEOData.propTypes = {
  translationID: PropTypes.string
};

SEOData.defaultProps = {
  translationID: 'metadata'
};

export default SEOData;
