module.exports = {
  locales: [
    'en-us',
    'en-gb',
    'fr',
    'it',
    'de',
    'es',
    'pt-br',
    'pl',
    'es-mx',
    'nl'
  ],
  defaultLocale: 'en-us'
};
