import React from 'react';
import PropTypes from 'prop-types';
import { Flex } from '@square-enix-private/vaquita-ui';
import { LogoSVG } from './SVGImage';
import { StyledLi, StyledUL } from './List';

import { FOOTER_PLATFORM as FOOTER_PLATFORM_QA } from '../../../constants/dataAutomationTagsQA';
import { FOOTER_PLATFORM as FOOTER_PLATFORM_ANALYTICS } from '../../../constants/dataAutomationTagsAnalytics';

export const Platforms = ({ data }) => (
  <Flex flexDirection="column" flex="1 0 auto" width={1}>
    <StyledUL width={1} data-type="logos" m="medium">
      {data.map(
        ({
          image,
          alt,
          id,
          link,
          fill,
          orientation,
          displayWidth,
          displayHeight
        }) => (
          <StyledLi key={id} m="medium">
            <a
              href={link}
              target="_blank"
              rel="noopener noreferrer"
              data-automation={FOOTER_PLATFORM_QA}
              data-analytics-id={FOOTER_PLATFORM_ANALYTICS}
            >
              <LogoSVG
                src={image.src}
                alt={alt}
                fill={fill || undefined}
                orientation={orientation || undefined}
                displayWidth={displayWidth || undefined}
                displayHeight={displayHeight || undefined}
              />
            </a>
          </StyledLi>
        )
      )}
    </StyledUL>
  </Flex>
);

Platforms.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      src: PropTypes.string,
      link: PropTypes.string,
      alt: PropTypes.string,
      fill: PropTypes.string,
      orientation: PropTypes.string,
      displayWidth: PropTypes.array,
      displayHeight: PropTypes.array,
      id: PropTypes.string.isRequired
    })
  )
};

Platforms.defaultProps = {
  data: []
};

export default Platforms;
