import React from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { Text } from '@square-enix-private/vaquita-ui';
import { useTranslation } from 'react-i18next';

import Wrapper from './Wrapper';

import { invalid } from '../../../actions/ageGate';

const Locked = ({ invalid }) => {
  const { t } = useTranslation('ageGate');

  invalid();

  return (
    <Wrapper>
      <Text color="text.primary">{t('ageLocked')}</Text>
    </Wrapper>
  );
};

Locked.propTypes = {
  invalid: PropTypes.func.isRequired
};

const mapDispatchToProps = { invalid };

export default connect(
  null,
  mapDispatchToProps
)(Locked);
