import React from 'react';
import PropTypes from 'prop-types';
import { Flex } from '@square-enix-private/vaquita-ui';
import { LogoSVG } from './SVGImage';
import { StyledLi, StyledUL } from './List';

export const Ratings = ({ data }) => (
  <Flex flexDirection="column" alignItems="center" justifyContent="center">
    <StyledUL width={1} data-type="logos" m="medium">
      {data.map(
        ({
          image,
          alt,
          id,
          link,
          fill,
          orientation,
          displayWidth,
          displayHeight
        }) => (
          <StyledLi key={id} m="medium">
            <a href={link} target="_blank" rel="noopener noreferrer">
              <LogoSVG
                src={image.src}
                alt={alt}
                fill={fill || undefined}
                orientation={orientation || undefined}
                displayWidth={displayWidth || undefined}
                displayHeight={displayHeight || undefined}
              />
            </a>
          </StyledLi>
        )
      )}
    </StyledUL>
  </Flex>
);

Ratings.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      src: PropTypes.string,
      link: PropTypes.string,
      alt: PropTypes.string,
      fill: PropTypes.string,
      orientation: PropTypes.string,
      displayWidth: PropTypes.array,
      displayHeight: PropTypes.array,
      id: PropTypes.string.isRequired
    })
  )
};

Ratings.defaultProps = {
  data: []
};

export default Ratings;
