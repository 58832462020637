import React from 'react';
import PropTypes from 'prop-types';
import { Flex } from '@square-enix-private/vaquita-ui';
import styled from '@emotion/styled';

const Element = styled(Flex)`
  border-radius: ${({ theme }) => `${theme.space.xsmall}px`};
  text-transform: uppercase;
`;

const Wrapper = ({ children }) => (
  <Element
    flexDirection="column"
    justifyContent="center"
    alignItems="center"
    p="medium"
    height="100%"
    width={1}
    margin="auto"
  >
    {children}
  </Element>
);

Wrapper.propTypes = {
  children: PropTypes.any.isRequired
};

export default Wrapper;
