import React from 'react';
import PropTypes from 'prop-types';

import styled from '@emotion/styled';
import { Flex, Text } from '@square-enix-private/vaquita-ui';
import { LogoSVG } from './SVGImage';

import { FOOTER_LEGAL_USK } from '../../../constants/dataAutomationTagsQA';

export const USKText = styled(Text)`
  margin-right: 15px;
  display: block;
  text-align: center;
  a {
    color: #fff;
    text-decoration: none;
  }
`;

export const StyledUL = styled.ul`
  width: 100%;
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-block-start: 0;
  margin-inline-start: 0;
  justify-content: center;
  padding: 0;

  ${({ theme }) => `
    margin: ${theme.space.small}px
  `}
`;

export const StyledLi = styled.li`
  max-width: 1200px;
  margin-bottom: 15px;
  text-align: justify;
`;

export const USK = ({ uskLegal }) => {
  if (!uskLegal) {
    return null;
  }

  return (
    <Flex alignItems="center" key={uskLegal.src}>
      <USKText
        data-automation={FOOTER_LEGAL_USK}
        fontSize="16px"
        lineHeight="24px"
        dangerouslySetInnerHTML={{ __html: uskLegal.address }}
      />
      <a href={uskLegal.link} target="_blank" rel="noopener noreferrer">
        <LogoSVG
          src={uskLegal.src}
          alt={uskLegal.alt}
          displayWidth={uskLegal.displayWidth}
          displayHeight={uskLegal.displayHeight}
        />
      </a>
    </Flex>
  );
};

USK.propTypes = {
  uskLegal: PropTypes.shape({
    address: PropTypes.string,
    alt: PropTypes.string,
    link: PropTypes.string,
    displayWidth: PropTypes.array,
    displayHeight: PropTypes.array,
    src: PropTypes.string
  })
};

USK.defaultProps = {
  uskLegal: null
};

export default USK;
