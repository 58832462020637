import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

export const defaultLocale = 'en-US';
export const defaultLng = 'en-us';
export const defaultNamespace = 'translations';

export const options = {
  fallbackLng: defaultLocale,
  lng: defaultLng,
  ns: [defaultNamespace],
  defaultNS: defaultNamespace,

  debug: false,

  interpolation: {
    escapeValue: false // not needed for react!!
  },

  react: {
    wait: true
  }
};

i18n.use(initReactI18next).init(options);

export { i18n };

export default i18n;
