const trim = require('./trim');

exports.onClientEntry = (
  nothing,
  {
    dataLayerName = 'dataLayer',
    defaultValue = 'homepage',
    site,
    siteType,
    environment,
    country,
    pageName
  }
) => {
  let gtmDevice = 'desktop';

  if (
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    )
  ) {
    gtmDevice = 'mobile';
  }

  const noSlashPath = document.location.pathname.slice(1);
  const postLanguageIndex = noSlashPath.indexOf('/') + 1;
  const language = document.location.pathname.slice(1, postLanguageIndex);
  const noLanguagePath = document.location.pathname.slice(postLanguageIndex);

  let pageID = noLanguagePath === '/' ? defaultValue : noLanguagePath;

  if (pageID) {
    pageID = trim(pageID, '/').replace(/\/+/g, '-');
  }

  window[dataLayerName] = window[dataLayerName] || [];

  window[dataLayerName].push({
    page: {
      pageInfo: {
        breadcrumbs: [pageID],
        country,
        destinationURL: document.location.href,
        environment,
        language,
        onsiteSearchResult: '',
        onsiteSearchTerm: '',
        pageID,
        pageName,
        referringURL: document.referrer,
        site,
        siteType,
        sysEnv: gtmDevice
      }
    },
    user: {
      profileInfo: {
        userId: ''
      }
    }
  });
};
