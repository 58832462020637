import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Text } from '@square-enix-private/vaquita-ui';

import { FOOTER_LEGAL_LINE } from '../../../constants/dataAutomationTagsQA';

export const StyledUL = styled.ul`
  width: 100%;
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-block-start: 0;
  margin-inline-start: 0;
  justify-content: center;
  padding: 0;

  ${({ theme }) => `
    margin: ${theme.space.small}px
  `}
`;

export const StyledLi = styled.li`
  max-width: 1200px;
  margin-bottom: 15px;
  text-align: justify;
`;

export const USKText = styled(Text)`
  margin-right: 15px;
  display: block;
  text-align: center;
  a {
    color: #fff;
    text-decoration: none;
  }
`;

export const LegalContent = ({ data }) => (
  <StyledUL data-type="legalContent">
    <StyledLi data-automation={FOOTER_LEGAL_LINE}>
      <Text fontSize="16px" lineHeight="24px">
        {data}
      </Text>
    </StyledLi>
  </StyledUL>
);

LegalContent.propTypes = {
  data: PropTypes.string
};

LegalContent.defaultProps = {
  data: ''
};

export default LegalContent;
