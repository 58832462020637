import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { compose, layout, space, variant } from 'styled-system';
import css from '@styled-system/css';
import shouldForwardProp from '@styled-system/should-forward-prop';
import {
  InnerWrapper,
  baseStyleSystem,
  baseCSS,
  variants,
  enhancedBaseCSS
} from './styles';

const createButtonFactory = (element) => {
  const Component = ({ children, ...props }) => {
    const WithStyledSystem = styled(element, {
      shouldForwardProp
    })(
      css(baseStyleSystem),
      baseCSS({ ...props }),
      variant({ variants }),
      enhancedBaseCSS({ ...props }),
      compose(layout, space)
    );

    return (
      <WithStyledSystem {...props}>
        <InnerWrapper>{children}</InnerWrapper>
      </WithStyledSystem>
    );
  };

  Component.propTypes = {
    children: PropTypes.any,
    variant: PropTypes.oneOf([
      'primary',
      'secondary',
      'smallIcons',
      'demoPlaforms'
    ])
  };

  Component.defaultProps = {
    children: null,
    variant: 'primary'
  };

  return Component;
};

export default createButtonFactory;
