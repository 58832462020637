import React from 'react';
import { i18n } from '.';

export const lngFormat = (locale) => locale.replace(/-[a-z]{2}$/, (e) => e.toUpperCase());

export const defaultProps = {
  lang: 'en',
  locale: 'en-us',
  i18n,
  ageGateFormat: 'dd/mm/yyyy',
  setLocale: (locale) => {
    defaultProps.locale = locale;
    defaultProps.lang = lngFormat(locale);
  },
  setAgeGateFormat: (format) => {
    defaultProps.ageGateFormat = format;
  }
};
export const LocaleContext = React.createContext(defaultProps);

export const addResources = (data, locale) => {
  if (data) {
    data.forEach(({ ns = 'translations', content }) => {
      const lng = lngFormat(locale);

      if (!i18n.hasResourceBundle(lng, ns)) {
        i18n.addResourceBundle(lng, ns, content, true, true);
      }
    });
  }
};

// Custom Hook
export const useChangeLanguage = (locale) => {
  const lang = lngFormat(locale);

  // using useSSR will not work on dev
  // useSSR(i18n, lang);
  if (!process.browser && i18n && !i18n.initializedStoreOnce) {
    i18n.changeLanguage(lang);
    i18n.services.resourceStore.data = i18n;
    i18n.initializedStoreOnce = true;
  }

  if (i18n.language !== lang) {
    i18n.changeLanguage(lang);
  }

  return lang;
};
