import React from 'react';
import PropTypes from 'prop-types';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'emotion-theming';
import theme from './themes/base';
import { i18n } from './i18n';

import createStore from './state/createStore';
import { LocaleContext, defaultProps } from './i18n/utils';
import GlobalStyles from './components/GlobalStyles';

const Providers = ({ children }) => {
  const store = createStore();

  return (
    <Provider store={store}>
      <I18nextProvider i18n={i18n}>
        <ThemeProvider theme={theme}>
          <GlobalStyles />
          <LocaleContext.Provider value={defaultProps}>
            {children}
          </LocaleContext.Provider>
        </ThemeProvider>
      </I18nextProvider>
    </Provider>
  );
};

Providers.propTypes = {
  children: PropTypes.any.isRequired
};

export default Providers;
