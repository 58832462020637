import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import styled from '@emotion/styled';
import { Text, Flex, Box } from '@square-enix-private/vaquita-ui';

import locales from '../../../config/locales';
import { getLanguageNativeName } from '../../../utils/localeNames';
import GlobeIcon from '../../../assets/globe-icon.png';

const SelectDropdown = styled.select`
  background-color: #fff;
  color: #000;
  display: block;
  width: 190px;
  padding: 10px;
  text-align: center;
  border: #fff;
`;

const SelectWrapper = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 220px;
  height: 45px;
  margin-top: 10px;
  background-color: #fff;
  background-image: url('${GlobeIcon}');
  background-repeat: no-repeat;
  background-size: auto 30%;
  background-position: calc(100% - 195px);
`;

const LocaleSwitcher = ({ locale }) => {
  const { t } = useTranslation('generics');

  const handleLanguageSwitch = (isoCode) => {
    window.location.assign(
      window.location.href.replace(`/${locale}/`, `/${isoCode}/`)
    );
  };

  return (
    <Flex flexDirection="column">
      <Text>{t('selectLanguage')}</Text>
      <SelectWrapper>
        <SelectDropdown
          defaultValue={locale}
          id="select-language"
          onChange={(e) => {
            handleLanguageSwitch(e.target.value);
          }}
        >
          {locales.locales.map((isoCode) => (
            <option value={isoCode} lang={isoCode} key={isoCode}>
              {getLanguageNativeName(isoCode)}
            </option>
          ))}
        </SelectDropdown>
      </SelectWrapper>
    </Flex>
  );
};

LocaleSwitcher.propTypes = {
  locale: PropTypes.node.isRequired
};

export default LocaleSwitcher;
