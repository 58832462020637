/* eslint-disable no-param-reassign */
import { getCookie } from '@square-enix-private/fe-cookie';
import { LOCKED, UNLOCKED, FORM } from '@square-enix-private/age-gate';

import {
  COOKIE_NAME,
  VALID_COOKIE,
  INVALID_COOKIE
} from '../../constants/ageGate';

export const getDateAsString = (date = new Date()) => date.toISOString().substr(0, 10);

// Info: Taken from the polyfill for padStart at https://github.com/behnammodi/polyfill/blob/master/string.polyfill.js#L291
export const padStart = (str, targetLength = 2, padString = '0') => {
  str = String(str);
  targetLength >>= 0; // floor if number or convert non-number to 0;
  padString = String(typeof padString !== 'undefined' ? padString : ' ');

  if (str.length > targetLength) {
    return str;
  }

  targetLength -= str.length;
  if (targetLength > padString.length) {
    padString += padString.repeat(targetLength / padString.length); // append to original to ensure we are longer than needed
  }

  return padString.slice(0, targetLength) + str;
};

export const getInitialState = () => {
  switch (getCookie(COOKIE_NAME)) {
    case VALID_COOKIE:
      return UNLOCKED;
    case INVALID_COOKIE:
      return LOCKED;
    default:
      return FORM;
  }
};
