import { Flex, Box } from '@square-enix-private/vaquita-ui';
import styled from '@emotion/styled';
import { style } from 'styled-system';

import { Button } from '~/components/Buttons';
import { breakpoints } from '~/themes/base';

import ButtonBackHover from '~/assets/Buttons/Small/Button_hover.png';

export const Background = styled(Flex)`
  background: rgba(0, 0, 0, 0.95);
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
`;

export const FlexRelative = styled(Flex)`
  position: relative;
  height: 100%;
  flex-direction: column;
`;

const hoverTextColor = style({
  prop: 'hoverTextColor',
  cssProperty: 'color',
  key: 'colors'
});

export const CloseButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;

  @media only screen and (orientation: landscape) and (max-width: ${breakpoints[1]}) {
    margin: 8px;
  }

  ::after {
    @supports (clip-path: circle(0% at 50% 50%)) {
      background-image: url(${ButtonBackHover});
      background-size: cover;
      filter: invert(1);
    }
  }

  @supports (-ms-scroll-limit: 0) {
    &:hover {
      background-image: url(${ButtonBackHover});
      background-size: cover;
      filter: unset;
      ${hoverTextColor};
    }
  }
`;

export const LightBoxContainer = styled(Box)`
  top: 0;
  left: 0;
  position: fixed;
  z-index: 10000000;
`;

export const ModalOptionsWrapper = styled(Flex)`
  pointer-events: none;

  > * {
    pointer-events: all;
  }
`;

export const FlexContainer = styled(Flex)`
  @media only screen and (orientation: landscape) and (max-width: ${breakpoints[1]}) {
    height: 100%;
    padding: 8px;
  }
`;
