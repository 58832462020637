import styled from '@emotion/styled';
import { Flex } from '@square-enix-private/vaquita-ui';

export const DesktopOnly = styled(Flex)`
  @media only screen and (max-width: ${({ theme: { breakpoints } }) => `calc(${breakpoints[1]} - 1px)`}) {
    display: none;
  }
`;

export const MobileOnly = styled(Flex)`
  @media only screen and (min-width: ${({ theme: { breakpoints } }) => breakpoints[1]}) {
    display: none;
  }
`;

export const PhoneOnly = styled(Flex)`
  @media only screen and (min-width: ${({ theme: { breakpoints } }) => breakpoints[0]}) {
    display: none;
  }
`;

export const TabletAndDesktop = styled(Flex)`
  @media only screen and (max-width: ${({ theme: { breakpoints } }) => breakpoints[0]}) {
    display: none;
  }
`;
