export const breakpoints = ['580px', '993px', '1921px'];

const colors = {
  text: {
    primary: '#ffffff',
    secondary: '#000',
    tertiary: '#7802E5',
    input: '#7802e5',
    headerLink: '#b0becb'
  },
  light: {
    primary: '#ffffff', // BRIGHT WHITE
    secondary: '#C62744', // NIGHT RED
    tertiary: '#C62744' // BURNT UMBER
  },
  dark: {
    primary: '#000000', // DEATH BLACK
    secondary: '#270D3A', // REVOLVER
    tertiary: '#7A3056' // FALCON
  }
};

const fonts = {
  KorolevCondensed: {
    normal: 'korolev-condensed, sans-serif'
  },
  OpenSans: {
    normal: 'Open Sans, sans-serif',
    bold: 'Open Sans, sans-serif'
  }
};

const fontSizes = {
  xsmall: 9,
  small: 12,
  medium: 16,
  large: 18,
  xlarge: 36,
  largeCounter: 42,
  xxlarge: 54
};

const space = {
  none: 0,
  xsmall: 4,
  small: 8,
  medium: 16,
  large: 32,
  xlarge: 64,
  xxlarge: 128
};

const theme = {
  breakpoints,
  colors,
  footer: {
    backgroundColor: '#000000'
  },
  fonts,
  fontSizes,
  space
};

export default theme;
