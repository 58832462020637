/* eslint-disable no-param-reassign */
/* eslint-disable no-prototype-builtins */
const isoLangs = {
  'en-us': {
    name: 'American English',
    nativeName: 'English (US)'
  },
  'en-gb': {
    name: 'British English',
    nativeName: 'English (UK)'
  },
  fr: {
    name: 'French',
    nativeName: 'Français (FR)'
  },
  it: {
    name: 'Italian',
    nativeName: 'Italiano (IT)'
  },
  de: {
    name: 'German',
    nativeName: 'Deutsch (DE)'
  },
  es: {
    name: 'Spanish (Spain)',
    nativeName: 'Español (ES)'
  },
  nl: {
    name: 'English',
    nativeName: 'Dutch (NL)'
  },
  'pt-br': {
    name: 'Brazilian Portuguese',
    nativeName: 'Português (BR)'
  },
  pl: {
    name: 'Polish',
    nativeName: 'Polski (PL)'
  }
};

export const getLanguageName = function getLanguageName(key) {
  if (isoLangs.hasOwnProperty(key.toLowerCase())) {
    return isoLangs[key.toLowerCase()].name;
  }

  key = key.slice(0, 2);
  const lang = isoLangs[key];

  return lang ? lang.name : undefined;
};

export const getLanguageNativeName = function getLanguageNativeName(key) {
  if (isoLangs.hasOwnProperty(key.toLowerCase())) {
    return isoLangs[key.toLowerCase()].nativeName;
  }

  key = key.slice(0, 2);
  const lang = isoLangs[key];

  return lang ? lang.nativeName : undefined;
};
