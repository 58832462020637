import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import styled from '@emotion/styled';
import { maxWidth, height, space, fontSize, color } from 'styled-system';
import { Box } from '@square-enix-private/vaquita-ui';
import { useTranslation } from 'react-i18next';
import { Button } from '~/components/Buttons';
import Lightbox from '~/components/Lightbox';
import AgeGate from '~/components/AgeGate';

import {
  NEWSLETTER_INPUT as NEWSLETTER_INPUT_QA,
  NEWSLETTER_BUTTON_SUBMIT as NEWSLETTER_BUTTON_SUBMIT_QA
} from '../../constants/dataAutomationTagsQA';
import {
  NEWSLETTER_INPUT as NEWSLETTER_INPUT_ANALYTICS,
  NEWSLETTER_BUTTON_SUBMIT as NEWSLETTER_BUTTON_SUBMIT_ANALYTICS
} from '../../constants/dataAutomationTagsAnalytics';

const Input = styled.input`
  border: none;
  border-radius: 2px;
  ${maxWidth};
  ${height};
  ${fontSize};
  ${color};
  ${space};
  ::placeholder {
    ${color};
    background: transparent;
  }
`;

const Form = styled.form`
  ${color};
  ${height};
  ${space};
`;

const IsVisible = ({ setVisible }) => {
  useEffect(() => {
    setVisible();
  }, []);

  return null;
};

const NewsletterForm = ({ locale, url, sendLabel, onSuccess, placeHolder }) => {
  const { t } = useTranslation('ageGate');
  const minAge = t('newsletterMinAge');
  const [email, setEmail] = useState('');
  const [validAge, setValidAge] = useState(!minAge);
  const [showAgeGate, setShowAgeGate] = useState(false);

  const onEmailChange = ({ currentTarget: { value } }) => {
    setEmail(value);
  };

  const onSubmitForm = async (event) => {
    event.preventDefault();

    await axios.post(url, {
      email,
      locale,
      tag: 'OUTRIDERS_NL'
    });
    setEmail('');
    onSuccess();
  };

  const setVisibleState = () => {
    if (!validAge) {
      setValidAge(true);
      setShowAgeGate(false);
    }
  };

  return (
    <>
      {showAgeGate && (
        <Lightbox onCloseModal={() => setShowAgeGate(false)}>
          <AgeGate minAge={minAge}>
            <IsVisible setVisible={setVisibleState} />
          </AgeGate>
        </Lightbox>
      )}
      <Form onSubmit={onSubmitForm} m="small">
        <Box width={['50px', null, 0]} display="inline-block" />
        <Input
          maxWidth="40vw"
          height={28}
          fontSize={['medium']}
          px={10}
          bg="#1A1A1A"
          type="email"
          pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,63}$"
          onChange={onEmailChange}
          placeholder={placeHolder}
          value={email}
          required
          data-automation={NEWSLETTER_INPUT_QA}
          data-analytics-id={NEWSLETTER_INPUT_ANALYTICS}
        />
        <Button
          width="auto"
          height={30}
          mr={['small', 'xsmall', 0]}
          px={['xsmall', 'small', 'medium']}
          type="submit"
          data-automation={NEWSLETTER_BUTTON_SUBMIT_QA}
          data-analytics-id={NEWSLETTER_BUTTON_SUBMIT_ANALYTICS}
          onClick={(e) => {
            if (!validAge) {
              e.preventDefault();
              setShowAgeGate(true);
            }
          }}
        >
          {sendLabel}
        </Button>
      </Form>
    </>
  );
};

NewsletterForm.propTypes = {
  url: PropTypes.string.isRequired,
  locale: PropTypes.string.isRequired,
  sendLabel: PropTypes.string,
  onSuccess: PropTypes.func,
  placeHolder: PropTypes.string
};

NewsletterForm.defaultProps = {
  sendLabel: 'Send',
  placeHolder: 'email@email.com',
  onSuccess: () => {}
};

export default NewsletterForm;
