import React from 'react';
import PropTypes from 'prop-types';
import createButtonFactory from './createButtonFactory';

const Link = createButtonFactory('a');

const ExternalLink = ({ children, ...props }) => (
  <Link {...props} rel=" noopener noreferrer" target="_blank" m="0">
    {children}
  </Link>
);

ExternalLink.propTypes = {
  children: PropTypes.any
};

ExternalLink.defaultProps = {
  children: null
};

export default ExternalLink;
